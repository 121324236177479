const exportable = {
  bg: `contentBg`,
  borderRadius: `lg`,
  transition: `
    transform 250ms ease,
    color 250ms ease
  `,
  boxShadow: `0 0 1px 0px black inset, 0 0 1px 0px black;`
}

export default exportable
