import common from './common'

export default {
  ...common.button,
  color: `black`,
  bg: `alpha`,
  borderColor: `alpha`,
  ':hover': {
    color: `black`,
    bg: `alphaLight`,
    borderColor: `alphaLight`
  }
}
